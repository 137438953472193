import React, { useState } from 'react';
import './Header.css'; // We'll style the header using this CSS file


const Header = ({ darkMode, toggleDarkMode }) => {


    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // const [darkMode, setDarkMode] = useState(false);

    // Toggle menu visibility
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // const toggleDarkMode = () => {
    //     setDarkMode(!darkMode);
    //     document.body.classList.toggle('dark-mode', !darkMode);
    // };



    return (
        <header className={`header ${darkMode ? 'dark' : ''}`}>
            <div className="logo">
                <h1>Miller Rojas</h1>
            </div>
            <div class="button-container">
                <button class="hamburger" onClick={toggleMenu}>
                    ☰
                </button>
            </div>
            <nav className={`nav-links ${isMenuOpen ? 'show' : ''}`}>
                <a href="#home" onClick={() => setIsMenuOpen(false)}>Home</a>
                <a href="#about" onClick={() => setIsMenuOpen(false)}>About</a>
                <a href="#projects" onClick={() => setIsMenuOpen(false)}>Projects</a>
                <a href="#contact" onClick={() => setIsMenuOpen(false)}>Contact</a>
            </nav>
            <button className="dark-mode-toggle" onClick={toggleDarkMode}>
                {darkMode ? '☀️ Light Mode' : '🌙 Dark Mode'}
            </button>
        </header>
    );
}

export default Header;

