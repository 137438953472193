import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

import './Contact.css';

const Contact = ({ darkMode }) => {

    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [statusMessage, setStatusMessage] = useState('');

    const form = useRef();
    const recaptchaRef = useRef();  // Adjust reference to `recaptchaRef`

    const sendEmail = (e) => {
        e.preventDefault();

        // Get the token from reCAPTCHA
        const captchaValue = recaptchaRef.current.getValue();
        console.log('reCAPTCHA Token:', captchaValue);  // Log the token to the console

        if (!captchaValue) {
            setStatusMessage('Please complete the reCAPTCHA.');
            return;
        }

        emailjs
            .sendForm('service_5xgbqdq', 'template_pi5j1fa', form.current, {
                publicKey: 'ltunE43OHXON2dhV2',
            })
            .then(
                () => {
                    setStatusMessage('Message sent successfully!');
                    alert('Message sent successfully!'); // This will show a popup window

                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setStatusMessage('Failed to send message.');
                },
            );
    };

    return (
        <section className={`contact ${darkMode ? 'dark' : ''}`} id="contact">
            <h2>Contact Me</h2>
            <form ref={form} onSubmit={sendEmail} className="contact-form">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="user_name"
                        value={formData.user_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="user_email"
                        value={formData.user_email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <ReCAPTCHA
                    ref={recaptchaRef}  // Assign ref to the ReCAPTCHA component
                    sitekey="6Ldb_SMqAAAAABWGiYIWrbAPfxf0jODnUpp1ypqU"
                />
                <button type="submit" className="submit-btn">Send Message</button>
                {statusMessage && <p>{statusMessage}</p>}
            </form>
        </section>
    );
}

export default Contact;
