import React from 'react';
import './Projects.css';

const projectsData = [
    {
        title: 'Project One',
        description: 'A brief description of the project and what it does.',
        techStack: 'React, Node.js, MongoDB',
        link: '#'
    },
    {
        title: 'Project Two',
        description: 'A brief description of the project and what it does.',
        techStack: 'Python, Django, PostgreSQL',
        link: '#'
    },
    {
        title: 'Project Three',
        description: 'A brief description of the project and what it does.',
        techStack: 'JavaScript, HTML, CSS',
        link: '#'
    },
];

const Projects = ({ darkMode, toggleDarkMode }) => {
    return (
        <section className={`projects ${darkMode ? 'dark' : ''}`} id="projects">
            <h2>Projects</h2>
            <div className="projects-grid">
                {projectsData.map((project, index) => (
                    <div key={index} className="project-card">
                        <h3>{project.title}</h3>
                        <p>{project.description}</p>
                        <p><strong>Tech Stack:</strong> {project.techStack}</p>
                        <a href={project.link} target="_blank" rel="noopener noreferrer">
                            View Project
                        </a>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Projects;
