import React, { useState } from 'react';
import './About.css';
// import profilePic from '../assets/FOTO_miller.png'; // Update the path to your image


const About = ({ darkMode }) => {
    return (
        <section className={`about ${darkMode ? 'dark' : ''}`} id="about">
            <div className="about-content">
                <div className="about-text">
                    <div class='about-description'>
                        <h2>About Me</h2>
                        <p>
                            Hi, I'm Alexander Miller Rojas Sanchez, a dedicated cybersecurity professional with a strong foundation in computer science. My expertise lies in areas such as network security, cloud security, and vulnerability assessment. I’ve developed a robust skill set, including Python scripting, Linux administration, and identity and access management. My goal is to enhance cybersecurity measures and protect critical assets from emerging threats.
                        </p>
                    </div>
                </div>
                <div className="about-skills">
                    <h3>Skills</h3>
                    <ul>
                        <li>✔️ Cloud</li>
                        <li>✔️ Web development</li>
                        <li>✔️ Network Security</li>
                        <li>✔️ Linux</li>
                        <li>✔️ Python</li>
                        <li>✔️ Threat Detection and Mitigation</li>
                        <li>✔️ Vulnerability Assessment</li>
                        <li>✔️ Identity and Access Management</li>
                        {/* Add more skills as needed */}
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default About;
